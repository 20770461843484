import { config } from '@abyss/web/tools/config';

/**
 * ListCommonCriteria
 *
 * Retrieves a list of common criteria from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListCommonCriteria = async (/* payload = {} */) => {
  try {
    // const theQueryKey = payload?.[0];
    // const thePayload = payload?.[1];

    /*
        const requestArgs = {
          baseURL: config('API_URL'),
          headers: {},
          params: { queryKey: theQueryKey },
        };
    */

    // const remoteResponse = await Axios.request(requestArgs);
    // return remoteResponse.data;

    return await new Promise((resolve) => {
      setTimeout(() => {}, 1000);
      return resolve([
        {
          id: '1',
          name: 'CommonCriteria item #1',
          filters: [
            {
              column: 'TAGS',
              condition: 'ALL',
              value: ['UA-DOB-CIP', 'TS-FARO', 'TS-IMDM', 'TS-TRANSUNION', 'LB-H_OPT'],
            },
            {
              column: 'TAGS',
              condition: 'NONE',
              value: ['TS-CIP', 'US-FARO', 'US-TRANSUNION', 'US-IMDM'],
            },
            {
              column: 'UNTRUSTED_SOURCE_COUNT',
              condition: 'LT',
              value: 2,
            },
            {
              column: 'TRUSTED_SOURCE_COUNT',
              condition: 'GTE',
              value: 3,
            },
            {
              column: 'TAGS',
              condition: 'ANY',
              value: ['DB-DAY', 'DB-MONTH', 'DB-YEAR', 'DB-FLIP', 'DB-OD_US'],
            },
            {
              column: 'TOTAL_TRUSTED_DOB_CHANGE_COUNT',
              condition: 'LTE',
              value: 3,
            },
          ],
        },
        {
          id: '2',
          name: 'CommonCriteria item #2',
          filters: [
            {
              column: 'TAGS',
              condition: 'ALL',
              value: ['US-IMDM', 'TS-IMDM', 'RC-03'],
            },
            {
              column: 'TAGS',
              condition: 'ANY',
              value: ['TS-CIP', 'US-CIP'],
            },
            {
              column: 'TAGS',
              condition: 'NONE',
              value: ['US-HSID', 'TS-HSID'],
            },
            {
              column: 'UNTRUSTED_SOURCE_COUNT',
              condition: 'LT',
              value: 2,
            },
          ],
        },
        {
          id: '3',
          name: 'CommonCriteria item #3',
          filters: [
            {
              column: 'TAGS',
              condition: 'ALL',
              value: ['US-FARO', 'RC-03', 'RS-R'],
            },
            {
              column: 'TAGS',
              condition: 'ANY',
              value: ['DB-FD_US', 'DB-OD_US', 'DB-OD_TS', 'DB-FD_TS'],
            },
          ],
        },
      ]);
    });
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/ListCommonCriteria.js -> ListCommonCriteria() -> error:', theError);

    throw error;
  }
};
