import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { config } from '@abyss/web/tools/config';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isNil, isNull, isUndefined } from 'lodash';
import { Loader } from '@src/components/Loader';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { useApi } from '@src/context/Api';
import { useApplicationContext } from '@src/context/Application';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { Visibility } from '@src/components/Visibility';
import { RiskRecords } from './steps/RiskRecords';
import { ReviewConfirm } from './steps/ReviewConfirm';
import { RemediateRecords } from './steps/RemediateRecords';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { ExitCriteria } from './steps/ExitCriteria';
import { EntranceCriteria } from './steps/EntranceCriteria';

/**
 * Wizard
 *
 * This is step-by-step component to compose an "action path". Supports both creation and modification of an action
 * path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Wizard = (props) => {
  const { actionPath, isManual, mode } = props;

  const { currentEnvironment } = useApplicationContext();

  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState({});
  const [previousStep, setPreviousStep] = useState({});
  const [nextStep, setNextStep] = useState({});
  const [firstStep, setFirstStep] = useState({});
  const [lastStep, setLastStep] = useState({});
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { useApiQueries, useApiMutation, clearApiCache } = useApi();

  const [SaveActionPath] = useApiMutation('SaveActionPath');

  const theAssets = [
    'ListActions',
    'ListActionStatuses',
    'ListChronoUnits',
    'ListRiskCodes',
    'ListSources',
    'ListTags',
    {
      key: 'ListActionPaths',
      args: { page: 0, size: 9999, sort: 'name,asc', statusList: ['ACTIVE'] },
    },
  ];

  const assets = useApiQueries(theAssets);

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();

  const { toast } = useToast();

  const defaultValues = {
    id: '',
    name: '',
    commonEntranceCriteria: [],
    commonExitCriteria: [],
    entranceCriteria: [],
    exitCriteria: [],
    remediation: {
      assignments: [],
      actionPathScopeCode: 'CURRENT_FUTURE',
      remediationMethod: 'OTHER',
      notes: '',
    },
    status: 'DRAFT',
  };

  const form = useForm({ defaultValues });

  const { isDirty, isValid, isSubmitting } = form?.formState;

  /**
   * Defines the steps and components to be used throughout the wizard/stepper experience.
   */
  useEffect(() => {
    const theSteps = [
      {
        component: EntranceCriteria,
        description: 'Specify entrance criteria to filter search results.',
        isManual: false,
        isValid: false,
        label: 'Entrance Criteria',
        order: 1,
        key: 'entranceCriteria',
      },
      {
        component: RiskRecords,
        description: 'Browse and select from a list of risk records to remediate.',
        isManual: false,
        isValid: false,
        label: 'Risk Records',
        order: 2,
        key: 'riskRecords',
      },
      {
        component: RemediateRecords,
        description: 'Specify actions to run on each record source.',
        isManual: true,
        isValid: false,
        label: 'Remediate Records',
        order: 3,
        key: 'remediateRecords',
      },
      {
        component: ExitCriteria,
        description: 'Specify exit criteria to filter search results.',
        isManual: false,
        isValid: false,
        label: 'Exit Criteria',
        order: 4,
        key: 'exitCriteria',
      },
      {
        component: ReviewConfirm,
        description: 'Verify the actions with the filtered records to remediate.',
        isManual: true,
        isValid: false,
        label: 'Review & Confirm',
        // @TODO: temporary - remove when ready (Feature: ExitCriteria)
        order: ['Local', 'Development'].includes(currentEnvironment) ? 5 : 4,
        // @TODO: temporary - remove when ready (Feature: ExitCriteria)
        key: 'reviewConfirm',
      },
    ]
      .filter((step) => {
        if (isManual) {
          return step?.isManual;
        }

        // @TODO: temporary - remove when ready (Feature: ExitCriteria)
        if (!['Local', 'Development'].includes(currentEnvironment) && step.label === 'Exit Criteria') {
          return false;
        }
        // @TODO: temporary - remove when ready (Feature: ExitCriteria)

        return true;
      })
      .map((step, index) => {
        const theStep = { ...step };

        if (isManual) {
          theStep.order = index + 1;
        }

        return theStep;
      });

    setSteps(theSteps);
  }, [isManual]);

  /**
   * Set isolated step details based on the current step number.
   */
  useEffect(() => {
    if (!isUndefined(routeParams.step)) {
      const stepNumber = Number(routeParams?.step) - 1 || 0;

      const theCurrentStep = steps?.[stepNumber];
      setCurrentStep(theCurrentStep);

      const theNextStep = steps?.[stepNumber + 1];
      setNextStep(theNextStep);

      const thePreviousStep = steps?.[stepNumber - 1];
      setPreviousStep(thePreviousStep);

      const theFirstStep = steps[0];
      setFirstStep(theFirstStep);

      const theLastStep = steps[steps.length - 1];
      setLastStep(theLastStep);
    }
  }, [path, routeParams, steps]);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(assets) &&
      Object.keys(assets).length === theAssets.length &&
      isEmpty(
        Object.keys(assets).filter((assetKey) => {
          const asset = assets[assetKey];
          return !(!asset?.isLoading && !asset?.isFetching);
        })
      )
    ) {
      setIsLoadingAssets(false);
    } else {
      setIsLoadingAssets(true);
    }
  }, [assets, theAssets]);

  /**
   * Mapping data loaded from API to the Wizard form state.
   */
  useEffect(() => {
    if (!isUndefined(actionPath) && !isEmpty(actionPath)) {
      const data = defaultValues;

      data.id = actionPath?.id;
      data.name = actionPath?.name;
      data.status = actionPath?.status;
      data.remediation.actionPathScopeCode = actionPath?.actionPathScopeCode;
      data.remediation.remediationMethod = actionPath?.remediationMethod;
      data.remediation.notes = actionPath?.note || '';

      if (!isUndefined(actionPath?.criteria?.entrance?.additional)) {
        data.entranceCriteria = actionPath?.criteria?.entrance?.additional?.map((filter) => {
          const theFilter = {
            field: filter?.column,
            condition: filter?.condition,
            value: filter?.value,
          };

          if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(theFilter?.field)) {
            theFilter.value = dayjs(theFilter?.value).format('MM/DD/YYYY');
          }

          if (theFilter?.field?.toLowerCase().includes('count')) {
            theFilter.value = String(theFilter?.value);
          }

          return theFilter;
        });
      }

      if (!isUndefined(actionPath?.assignments)) {
        data.remediation.assignments = actionPath?.assignments.map((action) => {
          const theAction = action;

          theAction.retryAttempts = String(theAction?.retryAttempts);
          theAction.retryInterval = String(theAction?.retryInterval);

          return theAction;
        });
        data.remediation.assignments = actionPath?.assignments;
      }

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [actionPath]);

  const localStorageValue = localStorage?.getItem('risk-analysis-filters');

  /**
   * Take the filters in local storage set from the risk analysis screen, and apply them to the form as
   * entranceCriteria.
   */
  useEffect(() => {
    if (isEmpty(actionPath) && !isNil(localStorageValue)) {
      const riskAnalysisFilters = JSON.parse(localStorageValue);

      const data = {
        ...defaultValues,
        ...{
          entranceCriteria: riskAnalysisFilters?.filters,
        },
      };

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });

      form?.validate(
        'name',
        () => {},
        () => {}
      );

      localStorage?.removeItem('risk-analysis-filters');
    }
  }, [actionPath, localStorageValue]);

  /**
   * handleSubmit
   *
   * Calls a remote API to save the form data into a database.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (!isLoading && isValid && !isSubmitting && isDirty) {
        setIsLoading(true);
        try {
          const payload = { ...submittedValues, ...{ manualAssociation: isManual } };
          const remoteResponse = await SaveActionPath(payload, {
            onSuccess: () => {
              clearApiCache(['ListActionPaths', 'GetActionPath']);
              toast.show({
                id: 'SaveActionPath',
                title: `Action Path Saved`,
                message: 'Action Path has been successfully saved.',
                variant: 'success',
              });
            },
            onError: () => {
              clearApiCache(['ListActionPaths', 'GetActionPath']);
            },
          });

          form?.reset(submittedValues, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });

          if (!isUndefined(remoteResponse?.id) && !isNull(remoteResponse?.id) && !isEmpty(remoteResponse?.id)) {
            form?.setValue('id', remoteResponse?.id);
          }
        } catch (error) {
          let theError = error;

          if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
            theError = JSON.stringify(error);
          }

          console.error('routes/private/ActionPaths/components/Wizard/Wizard.jsx -> handleSubmit():', theError);
        }
        setIsLoading(false);
      }
    },
    [isLoading, isDirty, isValid, isSubmitting, form?.formState?.errors]
  );
  const containerRef = useRef();

  const Component = currentStep?.component;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/Wizard.jsx">
      <Visibility>
        <LoadingOverlay
          loadingTitle="Loading..."
          loadingMessage="Your request is being processed."
          ariaLoadingLabel="Your request is being processed."
          isLoading={form?.formState?.isSubmitting}
        >
          {(mode === 'edit' && isUndefined(actionPath)) || isLoading || isLoadingAssets ? (
            <Loader verticalAlignment="top" />
          ) : (
            <FormProvider state={form} autoComplete="off" highlighted>
              <Grid>
                <Grid.Col
                  span={{
                    xs: '100%',
                  }}
                >
                  <Header
                    actionPath={actionPath}
                    currentStep={currentStep}
                    form={form}
                    handleSubmit={form?.handleSubmit(handleSubmit, () => {})}
                    steps={steps}
                    nextStep={nextStep}
                    previousStep={previousStep}
                    isManual={isManual}
                  />
                </Grid.Col>
                <Grid.Col
                  span={{
                    xs: '100%',
                  }}
                >
                  <div ref={containerRef}>
                    {!isUndefined(Component) && (
                      <Component
                        assets={assets}
                        currentStep={currentStep}
                        defaultValues={defaultValues}
                        form={form}
                        isLoading={isLoading}
                        isManual={isManual}
                      />
                    )}
                    <Footer
                      form={form}
                      handleSubmit={form?.handleSubmit(handleSubmit, () => {})}
                      steps={steps}
                      currentStep={currentStep}
                      previousStep={previousStep}
                      nextStep={nextStep}
                      firstStep={firstStep}
                      lastStep={lastStep}
                      isManual={isManual}
                    />
                  </div>
                </Grid.Col>
              </Grid>
            </FormProvider>
          )}
        </LoadingOverlay>
      </Visibility>
    </ErrorHandler>
  );
};

Wizard.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    actionPathScopeCode: PropTypes.string,
    remediationMethod: PropTypes.string,
    note: PropTypes.string,
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
    }),
    assignments: PropTypes.arrayOf(
      PropTypes.shape({
        actionId: PropTypes.number,
        actionName: PropTypes.string,
        actionType: PropTypes.string,
        retryAttempts: PropTypes.number,
        retryInterval: PropTypes.number,
      })
    ),
  }),
  isManual: PropTypes.bool,
  mode: PropTypes.string,
};

Wizard.defaultProps = {
  actionPath: {},
  isManual: false,
  mode: 'create',
};
