import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { every, isEmpty, isUndefined } from 'lodash';
import { dayjs } from '@abyss/web/tools/dayjs';

/**
 * filterEntranceCriteria
 *
 * if all filters are empty, remove them
 *
 * @param entranceCriteria
 * @returns {*[]}
 */
const filterEntranceCriteria = (entranceCriteria = []) => {
  let theEntranceCriteria = [];

  if (!isUndefined(entranceCriteria) && !isEmpty(entranceCriteria)) {
    const removeFilters = every(Object.values(entranceCriteria?.[0]), isEmpty);

    if (removeFilters === false) {
      theEntranceCriteria = entranceCriteria.map((filter) => {
        const theFilter = {
          column: filter?.field,
          condition: filter?.condition,
          value: filter?.value,
        };

        if (['LAST_MODIFIED_DATE', 'CREATED_DATE'].includes(filter?.field)) {
          theFilter.value = dayjs(filter?.value).format('YYYY-MM-DD');
        }

        if (filter?.field?.toLowerCase().includes('count')) {
          theFilter.value = Number(filter?.value);
        }

        return theFilter;
      });
    }
  }

  return theEntranceCriteria;
};

/**
 * filterAssignments
 *
 * if all assignments are empty, remove them
 *
 * @param assignments
 * @returns {*[]}
 */
const filterAssignments = (assignments = []) => {
  let theAssignments = [];

  if (!isUndefined(assignments) && !isEmpty(assignments)) {
    const removeAssignments = every(Object.values(assignments?.[0]), isEmpty);
    if (removeAssignments === false) {
      theAssignments = assignments;
    }
  }

  return theAssignments;
};

/**
 * SaveActionPath
 *
 * Saves a single action path with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveActionPath = async (payload = {}) => {
  try {
    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/action-paths',
      },
      data: {},
    };

    requestArgs.params = { ...{ mutationKey: payload?.[0] } };

    const thePayload = { ...payload?.[1] };

    if (!isUndefined(thePayload?.id) && !isEmpty(thePayload?.id)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
    }

    requestArgs.data.name = thePayload?.name;
    requestArgs.data.actionPathScopeCode = thePayload?.remediation?.actionPathScopeCode;
    requestArgs.data.remediationMethod = thePayload?.remediation?.remediationMethod;
    requestArgs.data.note = thePayload?.remediation?.notes;
    requestArgs.data.manualAssociation = thePayload?.manualAssociation;
    requestArgs.data.status = thePayload?.status;
    requestArgs.data.criteria = {
      entrance: {
        additional: [],
      },
    };

    const additionalEntranceCriteria = filterEntranceCriteria(thePayload?.entranceCriteria);

    if (!isEmpty(additionalEntranceCriteria)) {
      requestArgs.data.criteria.entrance.additional = additionalEntranceCriteria;
    }

    const assignments = filterAssignments(thePayload?.remediation?.assignments);

    if (!isEmpty(assignments)) {
      requestArgs.data.assignments = assignments;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveActionPath.js -> SaveActionPath() -> error:', theError);

    throw error;
  }
};
