import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { RiskRecords as Table } from '@src/tables';

/**
 * RiskRecords
 *
 * Provides the user with a screen to browse the risk records found based on the entrance criteria (filters) specified.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const RiskRecords = (props) => {
  const { currentStep, form } = props;

  const actionPathId = form?.getValues('id');
  const actionPathName = form?.getValues('name');
  const entranceCriteria = form.getValues('entranceCriteria');
  const commonCriteria = form.getValues('commonEntranceCriteria');

  const theCommonCriteria = useMemo(() => {
    return Object.keys(commonCriteria).filter((key) => {
      return commonCriteria[key];
    });
  }, [commonCriteria]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/RiskRecords/RiskRecords.jsx">
      <Grid>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Flex direction="column" alignContent="flex-start">
            <Flex direction="row" alignItems="center">
              <Heading offset={1}>{currentStep?.label}</Heading>
            </Flex>
            <p>{currentStep?.description}</p>
          </Flex>
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Table
            currentEntity={{
              id: actionPathId,
              name: actionPathName,
              type: 'actionPath',
            }}
            entranceCriteria={entranceCriteria}
            commonCriteria={theCommonCriteria}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

RiskRecords.propTypes = {
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  form: PropTypes.shape({
    getValues: PropTypes.func,
  }),
};

RiskRecords.defaultProps = {
  currentStep: {
    label: '',
    description: '',
  },
  form: {
    getValues: () => {},
  },
};
