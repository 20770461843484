import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Modal } from '@abyss/web/ui/Modal';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import { TheWidget } from './components/TheWidget';

/**
 * Widget
 *
 * Displays content in a box-like format with a title and optional description. Can be collapsible and have a menu.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Widget = (props) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  return (
    <ErrorHandler location="src/components/Widget/Widget.jsx">
      <Visibility>
        <Styles>
          <TheWidget {...{ ...props, ...{ isFullscreen, setIsFullscreen } }} />
          <Modal
            className="full-screen-modal widget-modal"
            title=""
            isOpen={isFullscreen}
            onClose={() => {
              return setIsFullscreen(false);
            }}
          >
            <Modal.Section>
              <TheWidget {...{ ...props, ...{ withCard: false, isFullscreen, setIsFullscreen } }} />
            </Modal.Section>
          </Modal>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Widget.propTypes = {
  backgroundColor: PropTypes.string,
  button: PropTypes.node,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
  description: PropTypes.string,
  icon: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onChange: PropTypes.func,
      radios: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
  withCard: PropTypes.bool,
};

Widget.defaultProps = {
  backgroundColor: '',
  button: null,
  children: null,
  collapsed: false,
  collapsible: false,
  description: '',
  icon: '',
  menu: [],
  withCard: true,
};
