import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isUndefined } from 'lodash';
import { Link } from '@abyss/web/ui/Link';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Visibility } from '@src/components/Visibility';

/**
 * AnalyzeRisksButton
 *
 * Button which when clicked will open the Risk Analysis screen with the supplied entrance criteria.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AnalyzeRisksButton = (props) => {
  const { actionPath, entranceCriteria, variant } = props;

  const router = useRouter();

  const [filters, setFilters] = useState([]);

  /**
   * set filters from entrance criteria
   */
  useEffect(() => {
    if (!isUndefined(entranceCriteria)) {
      const theFilters = entranceCriteria?.map((filter) => {
        return {
          field: !isUndefined(filter?.column) ? filter?.column : filter?.field,
          condition: filter?.condition,
          value: filter?.value,
        };
      });

      if (theFilters !== filters) {
        setFilters(theFilters);
      }
    }
  }, [entranceCriteria]);

  /**
   * set filters from action path
   */
  useEffect(() => {
    if (!isUndefined(actionPath)) {
      if (actionPath?.manualAssociation === true) {
        setFilters([
          {
            field: 'ACTION_PATH_ID',
            condition: 'ANY',
            value: [actionPath?.id],
          },
        ]);
      }

      if (actionPath?.manualAssociation === false) {
        setFilters(
          actionPath?.criteria?.entrance?.additional?.map((filter) => {
            return {
              field: filter?.column,
              condition: filter?.condition,
              value: filter?.value,
            };
          })
        );
      }
    }
  }, [actionPath]);

  return (
    <ErrorHandler location="src/components/AnalyzeRisksButton/AnalyzeRisksButton.jsx">
      <Visibility>
        {variant === 'button' && (
          <Button
            variant="solid"
            before={<IconSymbol icon="bar_chart" variant="outlined" />}
            onClick={async () => {
              const encoded = Buffer.from(JSON.stringify({ filters, views: [] })).toString('base64');
              router?.navigate(`/analysis/risk-analysis/${encoded}`);
            }}
          >
            Analyze Risks
          </Button>
        )}
        {variant === 'link' && (
          <Link
            before={<IconSymbol icon="bar_chart" variant="outlined" />}
            href="/analysis/risk-analysis"
            onClick={async (event) => {
              event?.preventDefault();
              const encoded = Buffer.from(JSON.stringify({ filters, views: [] })).toString('base64');
              router?.navigate(`/analysis/risk-analysis/${encoded}`);
            }}
          >
            Analyze Risks
          </Link>
        )}
      </Visibility>
    </ErrorHandler>
  );
};

AnalyzeRisksButton.propTypes = {
  actionPath: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
    }),
    id: PropTypes.string,
    manualAssociation: PropTypes.bool,
  }),
  entranceCriteria: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  variant: PropTypes.oneOf(['button', 'link']),
};

AnalyzeRisksButton.defaultProps = {
  actionPath: {},
  entranceCriteria: [],
  variant: 'button',
};
