import { GetRiskRecordTimeline } from '@src/requests/queries/GetRiskRecordTimeline';
import { GetTagHistory } from '@src/requests/queries/GetTagHistory';
import { GetActionPath } from './GetActionPath';
import { GetActionPathCount } from './GetActionPathCount';
import { GetActionPathStatistics } from './GetActionPathStatistics';
import { GetAggregationView } from './GetAggregationView';
import { GetEvent } from './GetEvent';
import { GetExportDetails } from './GetExportDetails';
import { GetRiskDashboardStatistics } from './GetRiskDashboardStatistics';
import { GetRiskRecord } from './GetRiskRecord';
import { GetRiskRecordsCount } from './GetRiskRecordsCount';
import { GetRiskRecordsDownloadUrl } from './GetRiskRecordsDownloadUrl';
import { GetRiskTrendReport } from './GetRiskTrendReport';
import { GetUserRoles } from './auth/GetUserRoles';
import { GetValidActionPathName } from './GetValidActionPathName';
import { ListActionPaths } from './ListActionPaths';
import { ListActions } from './ListActions';
import { ListActionStatuses } from './ListActionStatuses';
import { ListAlertConfigurations } from './ListAlertConfigurations';
import { ListAlerts } from './ListAlerts';
import { ListChronoUnits } from './ListChronoUnits';
import { ListCodeCategories } from './ListCodeCategories';
import { ListCodes } from './ListCodes';
import { ListCommonCriteria } from './ListCommonCriteria';
import { ListEvents } from './ListEvents';
import { ListEventTypes } from './ListEventTypes';
import { ListImpactedSystems } from './ListImpactedSystems';
import { ListRiskCodes } from './ListRiskCodes';
import { ListRiskRecords } from './ListRiskRecords';
import { ListSources } from './ListSources';
import { ListTags } from './ListTags';
import { ListTrustedValueHistory } from './ListTrustedValueHistory';
import { ListViews } from './ListViews';

/**
 * queries
 *
 * @type {{GetRiskRecordsCount: ((function({}=): Promise<*>)|*), GetValidActionPathName: ((function({}=):
 *   Promise<*>)|*), GetRiskTrendReport: ((function({}=): Promise<*>)|*), ListCodeCategories: ((function({}=):
 *   Promise<*>)|*), ListCommonCriteria: ((function(): Promise<*>)|*), GetExportDetails: ((function({}=):
 *   Promise<*>)|*), ListRiskCodes: ((function({}=): Promise<[]>)|*), GetRiskRecordTimeline: ((function({}=):
 *   Promise<*>)|*), ListAlertConfigurations: ((function({}=): Promise<*>)|*), ListAlerts: ((function({}=):
 *   Promise<*>)|*), ListActionPaths: ((function({}=): Promise<*>)|*), ListChronoUnits: ((function({}=):
 *   Promise<[]>)|*), GetRiskRecordsDownloadUrl: ((function(string=): Promise<string>)|*), GetTagHistory:
 *   ((function({}=): Promise<*>)|*), GetRiskDashboardStatistics: ((function({}=): Promise<*>)|*), GetActionPathCount:
 *   ((function({}=): Promise<*>)|*), ListActions: ((function({}=): Promise<*>)|*), GetUserRoles: ((function({}=):
 *   Promise<{}>)|*), GetRiskRecord: ((function({}=): Promise<*>)|*), ListSources: ((function({}=): Promise<[]>)|*),
 *   ListCodes: ((function({}=): Promise<*>)|*), ListRiskRecords: ((function({}=): Promise<*>)|*), ListViews:
 *   ((function({}=): Promise<*>)|*), ListImpactedSystems: ((function({}=): Promise<[]>)|*), GetActionPath:
 *   ((function({}=): Promise<*>)|*), GetActionPathStatistics: ((function({}=): Promise<*>)|*), ListEvents:
 *   ((function({}=): Promise<*>)|*), ListEventTypes: ((function({}=): Promise<[]>)|*), ListTrustedValueHistory:
 *   ((function({}=): Promise<*>)|*), GetAggregationView: ((function({}=): Promise<{}>)|*), ListTags: ((function({}=):
 *   Promise<*>)|*), GetEvent: ((function({}=): Promise<*>)|*), ListActionStatuses: ((function({}=): Promise<*>)|*)}}
 */
export const queries = {
  GetActionPath,
  GetActionPathCount,
  GetActionPathStatistics,
  GetAggregationView,
  GetEvent,
  GetExportDetails,
  GetRiskDashboardStatistics,
  GetRiskRecord,
  GetRiskRecordsCount,
  GetRiskRecordsDownloadUrl,
  GetRiskRecordTimeline,
  GetRiskTrendReport,
  GetTagHistory,
  GetUserRoles,
  GetValidActionPathName,
  ListActionPaths,
  ListActions,
  ListActionStatuses,
  ListAlertConfigurations,
  ListAlerts,
  ListChronoUnits,
  ListCodeCategories,
  ListCodes,
  ListCommonCriteria,
  ListEvents,
  ListEventTypes,
  ListImpactedSystems,
  ListRiskCodes,
  ListRiskRecords,
  ListSources,
  ListTags,
  ListTrustedValueHistory,
  ListViews,
};
