import React, { useEffect } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { Widget } from '@src/components/Widget';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { Grid } from '@abyss/web/ui/Grid';
import { Loader } from '@src/components/Loader';
import { Table } from './components/Table';
import { Styles } from './includes/styles';

/**
 * Widget: CommonCriteria
 *
 * Displays a list of common criteria filters to apply to an api request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const CommonCriteria = (props) => {
  const { actionPath, filterKey, isCollapsible } = props;

  const { useApiQuery } = useApi();

  const [ListCommonCriteria, { data: commonCriteria, isLoading, isFetching }] = useApiQuery('ListCommonCriteria');

  /**
   * Fetch common criteria from the API.
   */
  useEffect(() => {
    if (isUndefined(commonCriteria)) {
      ListCommonCriteria({ actionPathId: actionPath?.id });
    }
  }, [commonCriteria, actionPath]);

  return (
    <ErrorHandler location="src/widgets/CommonCriteria/CommonCriteria.jsx">
      <Styles>
        <Widget
          icon="settings"
          title="Common Criteria"
          backgroundColor="var(--abyss-colors-gray1)"
          collapsible={isCollapsible}
        >
          <Grid>
            {isUndefined(commonCriteria) || isLoading || isFetching ? (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Loader backgroundColor="transparent" height="100%" width="100%" />
              </Grid.Col>
            ) : (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Table rows={commonCriteria || []} filterKey={filterKey} />
              </Grid.Col>
            )}
          </Grid>
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

CommonCriteria.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
  filterKey: PropTypes.string,
  isCollapsible: PropTypes.bool,
};

CommonCriteria.defaultProps = {
  actionPath: {},
  filterKey: '',
  isCollapsible: false,
};
