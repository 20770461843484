import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { Card } from '@abyss/web/ui/Card';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isNil, isNull } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { SourcesTooltip } from '@src/tooltips';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useApi } from '@src/context/Api';
import { useToast } from '@abyss/web/hooks/useToast';
import { useUserContext } from '@src/context/User';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * Details
 *
 * Data containing sources and record ids for trusted and untrusted values.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Details = (props) => {
  const { actionPath, eid, refetchRiskRecord } = props;

  const [sourcesRemediated, setSourcesRemediated] = useState([]);
  const [link, setLink] = useState(null);

  const { roles } = useUserContext();

  const { toast } = useToast();

  const { useApiMutation, clearApiCache } = useApi();
  const [RiskRecordActionPathDisassociation] = useApiMutation('RiskRecordActionPathDisassociation');

  /**
   * Store the sources that have been remediated.
   */
  useEffect(() => {
    const sources = [];

    actionPath?.recordActions?.forEach((recordAction) => {
      if (!isEmpty(recordAction?.recordReferences)) {
        recordAction?.recordReferences.forEach((recordReference) => {
          if (!sources.includes(recordReference?.source)) {
            sources.push(recordReference?.source);
          }
        });
      }
    });

    if (sources !== sourcesRemediated) {
      setSourcesRemediated(sources);
    }
  }, [actionPath?.recordActions]);

  /**
   * Set the action path link based on the status of the action path.
   */
  useEffect(() => {
    if (String(actionPath?.status).toLowerCase() === 'active') {
      setLink(`/action-paths/${actionPath?.actionPathId}`);
    } else if (String(actionPath?.status).toLowerCase() !== 'inactive') {
      setLink(`/action-paths/${actionPath?.actionPathId}/edit/${String(actionPath?.status).toLowerCase()}`);
    }
  }, [actionPath?.status, actionPath?.actionPathId]);

  /**
   * handleDetach
   *
   * Sends an api request to detach action paths from the EID.
   *
   * @returns {Promise<void>}
   */
  const handleDetach = async () => {
    try {
      const toastId = `actionPath-disassociation-${actionPath?.actionPathId}`;
      toast.show({
        id: `${toastId}-info`,
        title: 'Detaching Action Paths from EID',
        message: 'Detaching Action Paths from EID...',
        isLoading: true,
        ariaLoadingLabel: 'Detaching Action Paths from EID',
        variant: 'info',
        autoClose: false,
      });

      const payload = {
        actionPaths: [actionPath?.actionPathId],
        eids: [eid],
      };

      await RiskRecordActionPathDisassociation(payload, {
        onSuccess: () => {
          clearApiCache(['GetRiskRecord']);
          refetchRiskRecord();
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: 'Detached Action Paths from EID',
            message: 'Action Paths have been successfully detached.',
            variant: 'success',
          });
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: 'Detaching Action Paths Failed',
            message: 'Unable to detach Action Paths.',
            variant: 'error',
          });
        },
      });
    } catch (error) {
      console.error('Error in handleDetach', error);
    }
  };

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Results/components/ActionPaths/components/Table/Table.jsx">
      <Visibility>
        <Styles>
          <Card size="small">
            <Grid css={{ padding: 0 }}>
              <Grid.Col
                span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}
                css={{ margin: '0px auto', paddingLeft: 0, paddingRight: 0 }}
              >
                <Grid>
                  <Grid.Col css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                    <Layout.Stack alignLayout="center">
                      <div>Status</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        <Text fontWeight="bold">{actionPath?.status}</Text>
                      </div>
                    </Layout.Stack>
                  </Grid.Col>
                  <Grid.Col css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                    <Layout.Stack alignLayout="center">
                      <div>Last Updated</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        <Text fontWeight="bold">
                          {dayjs(actionPath?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss')}
                        </Text>
                      </div>
                    </Layout.Stack>
                  </Grid.Col>
                  <Grid.Col css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                    <Layout.Stack alignLayout="center">
                      <div>Source(s) Remediated</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        {sourcesRemediated.length > 1 ? (
                          <Tooltip placement="auto" content={<SourcesTooltip sources={sourcesRemediated} />}>
                            <Text fontWeight="bold">
                              <span className="truncate">{sourcesRemediated.join(', ')}</span>
                            </Text>
                          </Tooltip>
                        ) : (
                          <React.Fragment>
                            {isNil(sourcesRemediated[0]) ? (
                              <Text color="var(--abyss-colors-gray4)">&mdash;</Text>
                            ) : (
                              <Text fontWeight="bold">{sourcesRemediated[0]}</Text>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                    </Layout.Stack>
                  </Grid.Col>
                  <Grid.Col css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                    <Layout.Stack alignLayout="center">
                      <div>Link</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        {!isNull(link) ? (
                          <Link href={link}>{actionPath?.name}</Link>
                        ) : (
                          <React.Fragment>{actionPath?.name}</React.Fragment>
                        )}
                      </div>
                    </Layout.Stack>
                  </Grid.Col>
                  {roles.includes('State.Write') && actionPath?.manualAssociation && (
                    <Grid.Col css={{ borderRight: '1px solid var(--abyss-colors-gray4);' }}>
                      <Layout.Stack alignLayout="center">
                        <div>Detach</div>
                        <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                          <Button
                            size="$sm"
                            before={<IconSymbol color="inherit" icon="remove_circle_outline" variant="outlined" />}
                            variant="destructive"
                            css={{
                              '& :hover .abyss-icon': {
                                color: 'white',
                              },
                            }}
                            onClick={async () => {
                              await handleDetach();
                            }}
                          >
                            Detach Action Path
                          </Button>
                        </div>
                      </Layout.Stack>
                    </Grid.Col>
                  )}
                </Grid>
              </Grid.Col>
            </Grid>
          </Card>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Details.propTypes = {
  actionPath: PropTypes.shape({
    actionPathId: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    manualAssociation: PropTypes.bool,
    name: PropTypes.string,
    recordActions: PropTypes.arrayOf(
      PropTypes.shape({
        recordReferences: PropTypes.arrayOf(
          PropTypes.shape({
            source: PropTypes.string,
          })
        ),
      })
    ),
    status: PropTypes.string,
  }),
  eid: PropTypes.string,
  refetchRiskRecord: PropTypes.func,
};

Details.defaultProps = {
  actionPath: {
    recordActions: [],
  },
  eid: '',
  refetchRiskRecord: () => {},
};
