import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Box } from '@abyss/web/ui/Box';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';
import { Table } from './components/Table/Table';
import { Styles } from './includes/styles';

/**
 * RemediateRecords
 *
 * Provides the user with a screen to browse the risk records found based on the entrance criteria (filters) specified.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemediateRecords = (props) => {
  const { assets, currentStep, defaultValues, form, isLoading, isManual } = props;

  const sources = assets?.ListSources?.data || [];
  const actions = assets?.ListActions?.data || [];
  const chronoUnits = assets?.ListChronoUnits?.data || [];

  const { currentRoute } = useRoutesContext();

  const { fields, append, remove, replace, swap } = useFormFieldArray({
    control: form.control,
    name: 'remediation.assignments',
  });

  const actionPathScopeCode = form.getValues('remediation.actionPathScopeCode');
  const remediationMethod = form.getValues('remediation.remediationMethod');
  const notes = form.getValues('remediation.notes');

  /**
   * Sets an initial row.
   */
  useEffect(() => {
    (async () => {
      if (isEmpty(fields)) {
        replace([
          {
            actionId: '',
            actionName: '',
            impactedSource: '',
            retryAttempts: '',
            retryInterval: '',
            retryIntervalUnit: '',
          },
        ]);
      }
    })();
  }, [fields]);

  /**
   * validate after setting initial row.
   */
  useEffect(() => {
    if (fields?.length === 1) {
      form.validate(
        `remediation.assignments.0.impactedSource`,
        () => {},
        () => {}
      );
    }
  }, [fields]);

  /**
   * handleReset
   *
   * @TODO - Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleReset = async () => {
    await form.setValue('remediation.assignments', defaultValues?.remediation?.assignments);
    replace(defaultValues?.remediation?.assignments);
  };

  /**
   * validateField
   *
   * @TODO - Needs description.
   *
   * @param field
   */
  const validateField = (field) => {
    form.validate(
      `remediation[${field}]`,
      () => {},
      () => {}
    );
  };

  /**
   * Validate all repeatable fields within a row when a value changes.
   */
  useEffect(() => {
    if (isLoading === false) {
      if (!isEmpty(actionPathScopeCode)) {
        validateField('actionPathScopeCode');
      }
      if (!isEmpty(remediationMethod)) {
        validateField('remediationMethod');
      }
      validateField('notes');
    }
  }, [actionPathScopeCode, remediationMethod, notes, isLoading]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/components/Wizard/steps/RemediateRecords/RemediateRecords.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col
              css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
              span={{
                xs: '100%',
              }}
            >
              <Heading offset={1}>{currentStep?.label} </Heading>
              <p>{currentStep?.description}</p>
              <Box
                color="$gray1"
                height="auto"
                css={{
                  position: 'relative',
                  border: '1px solid',
                  borderColor: themeConfiguration?.theme?.colors?.gray3,
                }}
              >
                <Flex style={{ position: 'relative', width: '100%' }} alignItems="center">
                  <div style={{ width: '20%', marginRight: 'var(--abyss-space-lg)' }}>
                    <SelectInput
                      label="Remediation Type"
                      placeholder="Select Type"
                      validators={{ required: true }}
                      onChange={() => {
                        validateField('remediationMethod');
                      }}
                      model="remediation[remediationMethod]"
                      options={[
                        { value: 'AUTOMATION', label: 'AUTOMATION' },
                        { value: 'BULK', label: 'BULK' },
                        { value: 'MANUAL', label: 'MANUAL' },
                        { value: 'OTHER', label: 'OTHER' },
                      ]}
                    />
                  </div>
                  {!isManual && (
                    <RadioGroup
                      label="Apply actions to"
                      display="row"
                      css={{
                        'abyss-radio-group-root': {
                          marginTop: '0 !important',
                        },
                      }}
                      model="remediation[actionPathScopeCode]"
                      validators={{ required: true }}
                      onChange={() => {
                        validateField('actionPathScopeCode');
                      }}
                    >
                      <RadioGroup.Radio label="Current records and future records" value="CURRENT_FUTURE" />
                      <RadioGroup.Radio label="Future records only" value="FUTURE" />
                    </RadioGroup>
                  )}
                  <div style={{ width: '25%', marginLeft: !isManual && 'var(--abyss-space-lg)' }}>
                    <TextInputArea
                      maxLength={2000}
                      displayMaxLength
                      disableAutoSize
                      label="Notes"
                      model="remediation[notes]"
                      validators={{ required: false }}
                      onChange={() => {
                        validateField('notes');
                      }}
                    />
                  </div>
                </Flex>
              </Box>
            </Grid.Col>

            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Layout.Group alignLayout="right">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  variant="custom"
                  id="addField"
                  onClick={() => {
                    return append({
                      actionId: '',
                      actionName: '',
                      impactedSource: '',
                      retryAttempts: '',
                      retryInterval: '',
                      retryIntervalUnit: '',
                    });
                  }}
                >
                  <Flex alignItems="center">
                    <div>
                      <IconSymbol icon="add_circle" variant="filled" />
                      <IconSymbol icon="add_circle" variant="outlined" />
                    </div>
                    <div>Add Action</div>
                  </Flex>
                </Link>
              </Layout.Group>
              <Table
                dataKey={`${currentRoute?.key}-remediateRecords`}
                actions={actions}
                append={append}
                chronoUnits={chronoUnits}
                form={form}
                handleReset={handleReset}
                isLoading={isLoading}
                remove={remove}
                sources={sources}
                swap={swap}
                replace={replace}
                fields={fields}
              />
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

RemediateRecords.propTypes = {
  assets: PropTypes.shape({
    ListSources: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListActions: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListChronoUnits: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  currentStep: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
  }),
  defaultValues: PropTypes.shape({
    remediation: PropTypes.shape({
      actionPathScopeCode: PropTypes.string,
      assignments: PropTypes.arrayOf(
        PropTypes.shape({
          actionId: PropTypes.string,
          actionName: PropTypes.string,
          impactedSource: PropTypes.string,
          retryAttempts: PropTypes.string,
          retryInterval: PropTypes.string,
          retryIntervalUnit: PropTypes.string,
        })
      ),
      notes: PropTypes.string,
      remediationMethod: PropTypes.string,
    }),
  }),
  form: PropTypes.shape({
    control: PropTypes.shape({
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          actionId: PropTypes.string,
          actionName: PropTypes.string,
          impactedSource: PropTypes.string,
          retryAttempts: PropTypes.string,
          retryInterval: PropTypes.string,
          retryIntervalUnit: PropTypes.string,
        })
      ),
      append: PropTypes.func,
      remove: PropTypes.func,
      replace: PropTypes.func,
      swap: PropTypes.func,
    }),
    getValues: PropTypes.func,
    setValue: PropTypes.func,
    validate: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
  isManual: PropTypes.bool,
};

RemediateRecords.defaultProps = {
  assets: {},
  currentStep: {},
  defaultValues: {},
  form: {},
  isLoading: false,
  isManual: false,
};
