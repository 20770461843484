import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { Filters } from '@src/components/Filters';
import { Styles } from './includes/styles';

/**
 * Widget: AdditionalCriteria
 *
 * Displays a list of additional criteria filters to apply to an api request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const AdditionalCriteria = (props) => {
  const { actionPaths, actionStatuses, form, isLoading, tags, filterKey, isCollapsible } = props;

  return (
    <ErrorHandler location="src/widgets/AdditionalCriteria/AdditionalCriteria.jsx">
      <Styles>
        <Widget
          icon="control_point_duplicate"
          title="Additional Criteria"
          backgroundColor="var(--abyss-colors-gray1)"
          collapsible={isCollapsible}
        >
          <Filters
            actionPaths={actionPaths}
            actionStatuses={actionStatuses}
            filterKey={filterKey}
            form={form}
            isLoading={isLoading}
            tags={tags}
          />
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

AdditionalCriteria.propTypes = {
  actionPaths: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  actionStatuses: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.shape({
    control: PropTypes.shape({}),
    setValue: PropTypes.func,
  }).isRequired,
  isLoading: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  filterKey: PropTypes.string,
  isCollapsible: PropTypes.bool,
};

AdditionalCriteria.defaultProps = {
  actionPaths: [],
  actionStatuses: [],
  isLoading: false,
  tags: [],
  filterKey: 'filters',
  isCollapsible: false,
};
