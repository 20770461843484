import React, { useMemo } from 'react';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/components/FilterCriteria';
import { Table as TableComponent } from '@src/components/Table-static';
import PropTypes from 'prop-types';
import { Styles } from './includes/styles';
import configuration from './includes/configuration.json';

/**
 * Table: CommonCriteria
 *
 * Displays a list of common criteria filters within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows, filterKey } = props;

  /**
   * Make fields more "human-readable"
   */
  const theRows = useMemo(() => {
    return rows?.map((row) => {
      return {
        ...row,
        ...{
          checkbox: true,
        },
      };
    });
  }, [rows]);

  /**
   * renderCellCheckbox
   *
   * Displays a checkbox in a cell indicating whether the common criteria filters are applied.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCheckbox = (args = {}) => {
    const { cell, row } = args;
    return <Checkbox label="" model={`${filterKey}[${row?.original?.name}]`} isChecked={cell?.value} />;
  };

  /**
   * renderCellName
   *
   * Displays the name of the common criteria filter.
   *
   * @param args
   * @returns {*}
   */
  const renderCellName = (args = {}) => {
    const { cell } = args;
    return cell?.value;
  };

  /**
   * renderCellCriteria
   *
   * Displays the common criteria filters.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellCriteria = (args = {}) => {
    const { row } = args;
    return <FilterCriteria filters={row?.original?.filters} showLabel={false} />;
  };

  /**
   * Columns for the table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns?.map((column) => {
      const theColumn = column;

      if (theColumn.accessor === 'checkbox') {
        theColumn.Cell = renderCellCheckbox;
      }

      if (theColumn.Header === 'Name') {
        theColumn.Cell = renderCellName;
      }

      if (theColumn.Header === 'Criteria') {
        theColumn.Cell = renderCellCriteria;
      }

      return theColumn;
    });
  }, []);

  return (
    <ErrorHandler location="src/widgets/CommonCriteria/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            rows: theRows,
            columns,
            configuration,
            dataKey: `actionPaths-${filterKey}`,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      checkbox: PropTypes.bool,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
    })
  ),
  filterKey: PropTypes.string,
};

Table.defaultProps = {
  rows: [],
  filterKey: '',
};
