import fieldConfiguration from '@src/components/Filters/components/Table/includes/fields.json';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isArray, isUndefined } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-static';
import { Visibility } from '@src/components/Visibility';
import { useApplicationContext } from '@src/context/Application';
import configuration from './includes/configuration.json';

/**
 * Table: FilterCriteria
 *
 * Displays a list of entrance criteria (filters) within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows, filterKey } = props;

  const { currentEnvironment } = useApplicationContext();

  const field = fieldConfiguration;

  /**
   * Make fields more "human-readable"
   *
   * @TODO add mapping for criteria type. commonCriteria or additionalCriteria? (Feature: CommonCriteria)
   */
  const theRows = useMemo(() => {
    const filters = [];

    rows.forEach((row) => {
      const theFilter = {
        field: !isUndefined(row?.column) ? row?.column : row?.field,
        condition: row?.condition,
        value: row?.value,
      };

      const column = field?.field?.options?.find((item) => {
        return item?.value === theFilter?.field;
      });

      if (!isUndefined(column)) {
        theFilter.field = column.label;
      }

      const condition = field?.condition?.options?.find((item) => {
        return item?.value === theFilter?.condition;
      });

      if (!isUndefined(condition)) {
        theFilter.condition = condition.label;
      }

      if (isArray(theFilter?.value)) {
        theFilter.value = theFilter.value.join(', ');
      }

      filters.push(theFilter);
    });

    return filters;
  }, [rows]);

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    // @TODO: temporary - remove when ready (Feature: CommonCriteria)
    if (!['Local', 'Development'].includes(currentEnvironment)) {
      return configuration?.initialColumns.filter((column) => {
        return column.accessor !== 'type';
      });
    }
    // @TODO: temporary - remove when ready (Feature: CommonCriteria)

    return configuration?.initialColumns;
  }, []);

  return (
    <ErrorHandler location="src/tables/ActionPaths/EntranceCriteria/Table.jsx">
      <Visibility>
        <TableComponent
          {...{
            rows: theRows,
            columns,
            configuration,
            dataKey: `actionPaths-${filterKey}`,
          }}
        />
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  filterKey: PropTypes.string,
};

Table.defaultProps = {
  rows: [],
  filterKey: 'filters',
};
